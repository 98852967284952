import React, { useContext, useState } from "react";
import * as S from "./AdminUtils.styles";
import { TokenContext } from "../AdminLayout";
import { cleanup } from "../../../api/appointment.api";
import Button from "../../Button/Button";
import { useAuth0 } from "@auth0/auth0-react";
import AdminLoginButton from "../../Header/AdminHeader/AdminLoginButton";
import { Link } from "gatsby";

type Props = {};

const AdminUtils = ({}: Props) => {
	const { isAuthenticated, user } = useAuth0();
	const token = useContext(TokenContext);
	const [deletedFreeTimeslotsCount, setDeletedFreeTimeslotsCount] =
		useState(-1);
	const [redeploying, setRedeploying] = useState(false);

	return (
		<S.Container>
			<S.Title>Admin panel</S.Title>
			<S.Version>20230923a</S.Version>
			<S.LoginInfo>
				<div>
					{isAuthenticated
						? `Bejelentkezve, mint ${user?.email}`
						: "Jelentkezz be!"}
				</div>
				<AdminLoginButton />
			</S.LoginInfo>
			<S.UtilsContainer>
				<S.Buttons>
					<Button
						appearance="danger"
						onClick={async () => {
							if (
								window.confirm(
									"Biztosan törölni szeretnéd a régi szabad időpntokat?"
								)
							) {
								const result = await cleanup(token);
								setDeletedFreeTimeslotsCount(
									(await result.json()) as unknown as number
								);
							}
						}}
						disabled={deletedFreeTimeslotsCount >= 0}
					>
						{deletedFreeTimeslotsCount < 0
							? "Régi szabad időpontok törlése"
							: `${deletedFreeTimeslotsCount} régi szabad időpont törölve`}
					</Button>
					<Button
						appearance="warning"
						onClick={() => {
							if (
								window.confirm(
									"Biztosan frissíteni szeretnéd a statikus adatokat?"
								)
							) {
								if (!process.env.GATSBY_CLOUDFLARE_DEPLOY_HOOK) return;
								fetch(process.env.GATSBY_CLOUDFLARE_DEPLOY_HOOK, {
									method: "POST",
								});
							}
						}}
					>
						Statikus adatok frissítése (redeploy)
					</Button>
				</S.Buttons>
				<S.BackToHome>
					<Link to="/">Vissza a főoldalra</Link>
				</S.BackToHome>
			</S.UtilsContainer>
		</S.Container>
	);
};

export default AdminUtils;
