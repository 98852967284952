import styled from "styled-components";

export const Container = styled.div``;

export const Title = styled.h1`
	margin: 0;
`;

export const Version = styled.div`
	::before {
		content: "v";
	}
`;

export const LoginInfo = styled.div`
	display: flex;
	align-items: center;

	button {
		margin-left: 1rem;
	}
`;

export const UtilsContainer = styled.div`
	margin-top: 1rem;
`;

export const BackToHome = styled.div`
	margin-top: 1rem;
`;

export const Buttons = styled.div`
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;

	text-align: center;
`;
