import React from "react";
import { PriceInfo } from "../../../../i18n/prices";
import Checkbox from "../../../Checkbox/Checkbox";
import Button from "../../../Button/Button";
import Input from "../../../Input/Input";
import { categoriesToNumbers } from "../AdminServices";
import * as S from "./AdminServiceEditor.styles";

type Props = {
	service: PriceInfo;
	isSoiled: boolean;
	onChange: (service: PriceInfo) => void;
	onSave: (service: PriceInfo) => void;
	onDelete: (service: PriceInfo) => void;
	onCategoryChange: (service: PriceInfo) => void;
	onOrderChange: (service: PriceInfo) => void;
};

const AdminServiceEditor = ({
	service,
	isSoiled,
	onChange,
	onSave,
	onDelete,
	onCategoryChange,
	onOrderChange,
}: Props) => {
	const handleChange = (updatedField: Partial<typeof service>) => {
		onChange({ ...service, ...updatedField });
	};

	const handleCategoryChange = (newCategory: number) => {
		if (newCategory === service.category) {
			return;
		}

		onCategoryChange({ ...service, category: newCategory });
	};

	const handleOrderChange = (newOrder: number) => {
		onOrderChange({ ...service, orderInCategory: newOrder });
	};

	return (
		<S.Container isSoiled={isSoiled}>
			<S.InputContainer>
				<S.InputLabel>Név</S.InputLabel>
				<Input
					value={service.name}
					onChange={(e) => handleChange({ name: e.currentTarget.value })}
				/>
			</S.InputContainer>
			<S.InputContainer isEn>
				<S.InputLabel>Name</S.InputLabel>
				<Input
					value={service.nameEn}
					onChange={(e) => handleChange({ nameEn: e.currentTarget.value })}
				/>
			</S.InputContainer>
			<S.TextAreaContainer>
				<S.InputLabel>Leírás</S.InputLabel>
				<textarea
					value={service.description}
					onChange={(e) => handleChange({ description: e.currentTarget.value })}
				/>
			</S.TextAreaContainer>
			<S.TextAreaContainer isEn>
				<S.InputLabel>Description</S.InputLabel>
				<textarea
					value={service.descriptionEn}
					onChange={(e) =>
						handleChange({ descriptionEn: e.currentTarget.value })
					}
				/>
			</S.TextAreaContainer>
			<S.InputContainer>
				<S.InputLabel>Idő min</S.InputLabel>
				<Input
					type="number"
					value={service.timeMin}
					onChange={(e) =>
						handleChange({ timeMin: e.currentTarget.valueAsNumber })
					}
				/>
			</S.InputContainer>
			<S.InputContainer>
				<S.InputLabel>Idő max</S.InputLabel>
				<Input
					type="number"
					value={service.timeMax}
					onChange={(e) =>
						handleChange({ timeMax: e.currentTarget.valueAsNumber })
					}
				/>
			</S.InputContainer>
			<S.InputContainer>
				<S.InputLabel>Ár (ft)</S.InputLabel>
				<Input
					type="number"
					value={service.price}
					onChange={(e) =>
						handleChange({ price: e.currentTarget.valueAsNumber })
					}
				/>
			</S.InputContainer>
			<S.InputContainer>
				<S.InputLabel>Rejtve</S.InputLabel>
				<Checkbox
					checked={!!service.hidden}
					onChange={(e) => handleChange({ hidden: e.currentTarget.checked })}
				/>
			</S.InputContainer>
			<S.Buttons>
				<Button
					onClick={() =>
						confirm(
							`Biztos, hogy törölni akarod a(z) "${service.name}" nevű szolgáltatást?`
						) && onDelete(service)
					}
					appearance="danger"
				>
					Törlés
				</Button>
				<S.CategoryOrder>
					<select
						onChange={(e) => handleCategoryChange(+e.currentTarget.value)}
						value={service.category}
					>
						{Object.keys(categoriesToNumbers).map((name, index) => (
							<option key={index} value={categoriesToNumbers[name]}>
								{name}
							</option>
						))}
					</select>
					<Input
						type="number"
						value={service.orderInCategory}
						onChange={(e) => handleOrderChange(e.currentTarget.valueAsNumber)}
					/>
				</S.CategoryOrder>
				<Button
					disabled={!isSoiled}
					onClick={() => onSave(service)}
					appearance="success"
				>
					Mentés
				</Button>
			</S.Buttons>
		</S.Container>
	);
};

export default AdminServiceEditor;
