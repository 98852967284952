import styled from "styled-components";

export const Container = styled.div``;

export const SearchContainer = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 1rem;

	> :not(:last-child) {
		margin-right: 1rem;
	}

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;

		> button {
			width: 10rem;
		}

		> :not(:first-child) {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: auto;
			margin-right: auto;
		}

		> * {
			width: 100%;

			:first-child {
				margin-bottom: 0.5rem;
			}

			:last-child {
				margin-top: 0.5rem;
			}
		}
	}
`;

export const Results = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
`;

export const ResultsTitle = styled.div`
	font-weight: bold;
`;
