import React from "react";
import AdminUtils from "../AdminUtils/AdminUtils";

type Props = { default: boolean };

const AdminIndex = ({}: Props) => {
	return <AdminUtils />;
};

export default AdminIndex;
