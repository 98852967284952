import dayjs from "dayjs";
import React, {
	ChangeEvent,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	deleteTimeslot,
	getFreeTimeslotsAfter,
	saveFreeTimeslots,
} from "../../../api/timeslots.api";
import {
	getMonthName,
	getWeekDayName,
	parseTimeslot,
	timeslotsToCalendarTimeslots,
} from "../../../util/date";
import Button from "../../Button/Button";
import Calendar from "../../Calendar/Calendar";
import Input from "../../Input/Input";
import { TokenContext } from "../AdminLayout";
import * as S from "./AdminAvailability.styles";

export type Timeslot = {
	id: number;
	from: dayjs.Dayjs;
	to: dayjs.Dayjs;
	taken?: boolean;
};

export type TimeslotString = `${number}:${number}-${number}:${number}`;

export type FreeTimeslots = {
	[year: number]: {
		[month: number]: {
			[day: number]: {
				[id: number]: TimeslotString;
			};
		};
	};
};

type Props = { default: boolean };

const AdminAvailability = ({}: Props) => {
	const token = useContext(TokenContext);

	const [timeslots, setTimeslots] = useState<FreeTimeslots>({});
	const [weekdays, setWeekdays] = useState<dayjs.Dayjs[]>([]);
	const [availability, setAvailability] = useState<{
		[index: number]: Timeslot[];
	}>({});

	const fetchFreeTimeslots = async () => {
		const newTimeslots = await getFreeTimeslotsAfter(
			token,
			weekdays[0].clone().hour(0).minute(0)
		);
		setTimeslots(newTimeslots);
	};

	useEffect(() => {
		if (!weekdays?.length || !token?.length) return;
		fetchFreeTimeslots();
	}, [weekdays, token]);

	const calendarTimeslots = useMemo(() => {
		return timeslotsToCalendarTimeslots(timeslots, weekdays, true);
	}, [timeslots, weekdays, availability]);

	const sortAvailability = (slots: typeof availability) => {
		const sorted = { ...slots };

		for (const key in sorted) {
			sorted[key].sort(
				(a, b) =>
					a?.from?.hour() * 60 +
					a?.from?.minute() -
					(b?.from?.hour() * 60 + b?.from?.minute())
			);
		}

		setAvailability(sorted);
	};

	useEffect(() => {
		const newAvailabilities = weekdays.reduce((acc, day, index) => {
			const timeslotData = {
				...timeslots?.[day.year()]?.[day.month() + 1]?.[day.date()],
			};

			for (const key in timeslotData) {
				if (key.startsWith("-")) {
					delete timeslotData[key];
				}
			}

			acc[index] = Object.keys(timeslotData ?? {}).length
				? Object.entries(timeslotData).map(([id, timeslot]) =>
						parseTimeslot(timeslot, +id, day)
				  )
				: [{ id: 0 } as Timeslot];

			return acc;
		}, {} as typeof availability);

		sortAvailability(newAvailabilities);
	}, [weekdays, timeslots]);

	const handleInputChange =
		(index: number, timeslotIndex: number, isFrom: boolean, isHour: boolean) =>
		(e: ChangeEvent<HTMLInputElement>) => {
			const previousAvailability = availability[index]?.[timeslotIndex] ?? {};
			const date =
				previousAvailability[isFrom ? "from" : "to"] ?? dayjs(weekdays[index]);

			const num = e.currentTarget.value.replace(/\D/g, "");
			if (isNaN(+num)) {
				return;
			}

			if (!e.currentTarget.value) {
				e.currentTarget.value = "";
			}

			const unit = isHour
				? Math.min(+num, 23, Math.max(+num, 0))
				: Math.min(+num, 59, Math.max(+num, 0));

			const newDate = date.set(isHour ? "hours" : "minutes", unit);
			const newTimeslots = [...(availability[index] ?? [])];
			if (newTimeslots[timeslotIndex]) {
				newTimeslots[timeslotIndex] = {
					...previousAvailability,
					...(isFrom ? { from: newDate } : { to: newDate }),
				};
			}
			setAvailability({
				...availability,
				[index]: newTimeslots,
			});
		};

	const addTimeslotForDay = (dayIndex: number) => () => {
		const previousTimeslots = availability[dayIndex] ?? [];
		const newAvailabilities = {
			...availability,
			[dayIndex]: [...previousTimeslots, { id: 0 } as Timeslot],
		};
		sortAvailability(newAvailabilities);
	};

	const deleteTimeslotForDay =
		(dayIndex: number, timeslotIndex: number) => () => {
			const newTimeslots = availability[dayIndex] ?? [];
			if (newTimeslots[timeslotIndex]?.id > 0) {
				deleteTimeslot(token, newTimeslots[timeslotIndex]);
			}
			if (newTimeslots?.length > 1) {
				setAvailability({
					...availability,
					[dayIndex]: newTimeslots.filter(
						(timeslot) => timeslot !== newTimeslots[timeslotIndex]
					),
				});
			} else {
				setAvailability({
					...availability,
					[dayIndex]: [
						{
							...newTimeslots[0],
							from: newTimeslots[0].from.hour(0).minute(0),
							to: newTimeslots[0].to.hour(0).minute(0),
						},
					],
				});
			}
		};

	const saveAvailability = async () => {
		await saveFreeTimeslots(
			token,
			Object.values(availability)
				.flat()
				.filter((timeslot) => timeslot.from && timeslot.to)
		);

		fetchFreeTimeslots();
	};

	const isDayCorrect = (index: number) => {
		const current = availability[index];
		return current?.every((timeslot) => {
			return timeslot?.from && timeslot?.to;
		});
	};

	// const handleAutoChop = () => {
	// 	const newAvailabilities: typeof availability = {};
	// 	Object.entries(availability).forEach(([dayIndex, freeTimeslotsForDay]) => {
	// 		if (!newAvailabilities[dayIndex]) {
	// 			newAvailabilities[dayIndex] = [];
	// 		}

	// 		const calendarTimeslotsForDay: Timeslot[] = (calendarTimeslots[dayIndex] ?? []);
	// 		const takenTimeslots = calendarTimeslotsForDay.filter((timeslot) => timeslot.taken);

	// 		freeTimeslotsForDay.forEach((timeslot) => {
	// 			// TODO
	// 		});
	// 	});

	// 	setAvailability(newAvailabilities);
	// };

	return (
		<S.Container>
			<Calendar
				onWeekChange={(e) => setWeekdays(e)}
				timeslots={calendarTimeslots}
				isAdmin
			/>
			<S.Week>
				{weekdays.map((day, dayIndex) => (
					<S.Day key={dayIndex} isCorrect={isDayCorrect(dayIndex)}>
						<S.DayHeader>
							{getWeekDayName(day)} - {getMonthName(day, true)} {day.date()}.
						</S.DayHeader>
						{availability[dayIndex] &&
							availability[dayIndex].map((timeslot, timeslotIndex) => (
								<div key={timeslotIndex}>
									<S.DayBody>
										<Input
											placeholder="tól"
											type="numeric"
											value={
												timeslot?.from?.hour() ?? ("" as unknown as number)
											}
											onChange={handleInputChange(
												dayIndex,
												timeslotIndex,
												true,
												true
											)}
										/>
										:
										<Input
											type="numeric"
											value={
												timeslot?.from?.minute() ?? ("" as unknown as number)
											}
											onChange={handleInputChange(
												dayIndex,
												timeslotIndex,
												true,
												false
											)}
										/>
										-
										<Input
											placeholder="ig"
											type="numeric"
											value={timeslot?.to?.hour() ?? ("" as unknown as number)}
											onChange={handleInputChange(
												dayIndex,
												timeslotIndex,
												false,
												true
											)}
										/>
										:
										<Input
											type="numeric"
											value={
												timeslot?.to?.minute() ?? ("" as unknown as number)
											}
											onChange={handleInputChange(
												dayIndex,
												timeslotIndex,
												false,
												false
											)}
										/>
									</S.DayBody>
									<S.Buttons>
										{
											<Button
												appearance="danger"
												size="small"
												onClick={deleteTimeslotForDay(dayIndex, timeslotIndex)}
											>
												Törlés
											</Button>
										}
										{timeslotIndex === availability[dayIndex].length - 1 ? (
											<Button
												appearance="success"
												onClick={addTimeslotForDay(dayIndex)}
												size="small"
											>
												+ Új
											</Button>
										) : (
											<div />
										)}
									</S.Buttons>
								</div>
							))}
					</S.Day>
				))}
			</S.Week>
			<S.Buttons>
				<div>
					<Button appearance="success" onClick={saveAvailability}>
						Időpontok mentése
					</Button>
					{/* <Button appearance="primary" onClick={handleAutoChop}>
						Auto-darabolás
					</Button> */}
				</div>
			</S.Buttons>
		</S.Container>
	);
};

export default AdminAvailability;
