import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Spin } from "../../../util/animation";

export const RouteLoading = styled(FontAwesomeIcon)`
	animation: ${Spin} 1s linear infinite;
	font-size: 2rem;
	max-width: 2rem;
	max-height: 2rem;
`;
