import { Auth0Provider } from "@auth0/auth0-react";
import React, { useMemo } from "react";
import AdminLayout from "../../components/Admin/AdminLayout";
import AdminAvailability from "../../components/Admin/AdminAvailability/AdminAvailability";
import AdminServices from "../../components/Admin/AdminServices/AdminServices";
import PrivateRoute from "../../components/Admin/PrivateRoute/PrivateRoute";
import AdminIndex from "../../components/Admin/AdminIndex/AdminIndex";
import permissions from "../../components/Admin/permissions";
import AdminAppointments from "../../components/Admin/AdminAppointments/AdminAppointments";
import { isBrowser } from "../../util/browser";
import { useLocation } from "@reach/router";
import AdminCustomers from "../../components/Admin/AdminCustomers/AdminCustomers";

const app = ({}) => {
	const params = useLocation();
	const pagePath = new URLSearchParams(params.search).get("p");

	const page = useMemo(() => {
		switch (pagePath) {
			case "appointments":
				return <AdminAppointments default={false} />;
			case "availability":
				return <AdminAvailability default={false} />;
			case "services":
				return <AdminServices default={false} />;
			case "customers":
				return <AdminCustomers default={false} />;
			default:
				return <AdminIndex default />;
		}
	}, [pagePath]);

	return (
		<Auth0Provider
			domain={process.env.GATSBY_AUTH0_DOMAIN!}
			clientId={process.env.GATSBY_AUTH0_CLIENT_ID!}
			audience={process.env.GATSBY_AUTH0_AUDIENCE}
			redirectUri={`${
				isBrowser() ? window.location.origin : "csermakleila.hu"
			}/admin22?p=${pagePath}`}
			scope={permissions.adminScopes}
			useRefreshTokens
			cacheLocation="localstorage"
		>
			<AdminLayout>
				<PrivateRoute>{page}</PrivateRoute>
			</AdminLayout>
		</Auth0Provider>
	);
};

export default app;
