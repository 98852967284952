import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { isBrowser } from "../../../util/browser";
import Button from "../../Button/Button";

const AdminLoginButton = () => {
	const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();

	return (
		<>
			{isLoading ? (
				<Button size="small" disabled>
					Betöltés...
				</Button>
			) : (
				<Button
					appearance="primary"
					onClick={() =>
						isAuthenticated
							? logout({ returnTo: isBrowser() ? window.location.origin : "" })
							: loginWithRedirect()
					}
					size="small"
				>
					{isAuthenticated ? "Kijelentkezés" : "Bejelentkezés"}
				</Button>
			)}
		</>
	);
};

export default AdminLoginButton;
