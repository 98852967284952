import styled from "styled-components";

export const Container = styled.div`
	flex: 1;
`;

export const Week = styled.div`
	display: flex;
	padding-top: 1rem;
	padding-left: 3rem;
	overflow-x: scroll;

	::-webkit-scrollbar {
		display: none;
	}

	scrollbar-width: none;
`;

export const Day = styled.div<{ isCorrect: boolean }>`
	padding: 7px;
	border: 0.5px solid black;
	background-color: ${({ isCorrect }) => (isCorrect ? "lightgreen" : "unset")};
`;

export const DayHeader = styled.div`
	font-weight: bold;
	border-bottom: 1px solid black;
`;

export const DayBody = styled.div`
	display: flex;
	width: 112px;
	margin-top: 0.5rem;

	input {
		width: 1.5rem;
		padding: 0;
		text-align: center;
		border-width: 1px;
		border-radius: 4px;
		font-size: 14px;
	}
`;

export const Buttons = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 0.5rem;

	button:first-of-type {
		margin-right: 0.5rem;
	}
`;
