import React, { useContext, useEffect, useState } from "react";
import ServiceList from "../../../data/services.json";
import * as S from "./AdminServices.styles";
import AdminServiceEditor from "./AdminServiceEditor/AdminServiceEditor";
import Loading from "../Loading/Loading";
import { PriceInfo } from "../../../i18n/prices";
import Services from "../../../data/services.json";
import { jsonAuthHeaders } from "../../../util/network";
import { TokenContext } from "../AdminLayout";
import Button from "../../Button/Button";

type ServiceCategory = keyof typeof Services;

export const categoriesToNumbers: Record<keyof typeof ServiceList, number> = {
	Manicure: 0,
	Pedicure: 1,
	Nails: 2,
	Decoration: 3,
};

type Props = { default: boolean };

const AdminServices = ({}: Props) => {
	const token = useContext(TokenContext);

	const [services, setServices] = useState<typeof ServiceList>();
	const [soiledServices, setSoiledServices] = useState<Record<number, true>>(
		{}
	);

	const fetchServices = async () => {
		const result = await fetch(`${process.env.GATSBY_API_URL}/service`);
		const json: typeof Services = await result.json();
		const services = {} as typeof json;
		Object.keys(categoriesToNumbers).forEach((key) => {
			services[key] = json[key];
		});
		setServices(services);
	};

	useEffect(() => {
		fetchServices();
	}, []);

	if (!services) {
		return <Loading />;
	}

	const handleChange = (key: ServiceCategory, updatedService: PriceInfo) => {
		const index = services[key].findIndex(
			(service) => service.id === updatedService.id
		);
		const updatedServices = { ...services, [key]: [...services[key]] };
		updatedServices[key][index] = updatedService as any;
		setServices(updatedServices);
		setSoiledServices({ ...soiledServices, [updatedService.id]: true });
	};

	const handleSave = (service: PriceInfo) => {
		const isNew = !service.id;
		(async () => {
			try {
				if (isNew) {
					await fetch(`${process.env.GATSBY_API_URL}/service`, {
						method: "POST",
						body: JSON.stringify(service),
						headers: jsonAuthHeaders(token),
					});
				} else {
					await fetch(`${process.env.GATSBY_API_URL}/service/${service.id}`, {
						method: "PUT",
						body: JSON.stringify(service),
						headers: jsonAuthHeaders(token),
					});
				}

				const newSoiledServices = { ...soiledServices };
				delete newSoiledServices[service.id];
				setSoiledServices(newSoiledServices);
				fetchServices();
			} catch (err) {
				alert(`Hiba mentés közben! ${JSON.stringify(err)}`);
			}
		})();
	};

	const handleNewClick = (key: ServiceCategory) => {
		let orderInCategory = 0;
		services[key].forEach((service) => {
			if (service.orderInCategory >= orderInCategory) {
				orderInCategory = service.orderInCategory + 1;
			}
		});

		setServices({
			...services,
			[key]: [
				...services[key],
				{
					id: 0,
					category: categoriesToNumbers[key],
					orderInCategory,
					description: "",
					name: "",
					price: "" as unknown as number,
					timeMin: "" as unknown as number,
					timeMax: "" as unknown as number,
				} as PriceInfo,
			],
		});
		setSoiledServices({ ...soiledServices, 0: true });
	};

	const handleDelete = (key: ServiceCategory, service: PriceInfo) => {
		setServices({
			...services,
			[key]: [...services[key].filter((s) => s.id !== service.id)],
		});
		(async () => {
			await fetch(`${process.env.GATSBY_API_URL}/service/${service.id}`, {
				method: "DELETE",
			});
		})();
	};

	const handleCategoryChange = (service: PriceInfo) => {
		(async () => {
			await fetch(
				`${process.env.GATSBY_API_URL}/service/updateCategory/${service.id}/${service.category}`,
				{
					method: "PATCH",
				}
			);

			fetchServices();
		})();
	};

	const handleOrderChange = (key: ServiceCategory, service: PriceInfo) => {
		const { id, orderInCategory } = service;

		if (orderInCategory < 0 || orderInCategory >= services[key].length) {
			return;
		}

		(async () => {
			await fetch(
				`${process.env.GATSBY_API_URL}/service/updateOrder/${id}/${orderInCategory}`,
				{
					method: "PATCH",
				}
			);

			fetchServices();
		})();
	};

	return (
		<S.Container>
			{(Object.keys(services) as ServiceCategory[]).map((key) => (
				<S.Category key={key}>
					<h2>{key}</h2>
					{services[key].map((service) => (
						<AdminServiceEditor
							key={service.id}
							service={service}
							isSoiled={!!soiledServices[service.id]}
							onChange={(service) => handleChange(key, service)}
							onSave={handleSave}
							onDelete={(service) => handleDelete(key, service)}
							onCategoryChange={handleCategoryChange}
							onOrderChange={(e) => handleOrderChange(key, e)}
						/>
					))}
					<div>
						<Button
							onClick={() => handleNewClick(key)}
							disabled={soiledServices[0]}
						>
							+ Új hozzáadása
						</Button>
					</div>
				</S.Category>
			))}
		</S.Container>
	);
};

export default AdminServices;
