import styled from "styled-components";
import { colors, screenWidths } from "../../../../util/theme";

export const Container = styled.div<{ isSoiled: boolean }>`
	border: 0.125rem solid ${({ isSoiled }) => (isSoiled ? "red" : "#373737")};
	border-radius: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0.5rem;
	background: #ddd;
`;

export const InputContainer = styled.div<{ isEn?: boolean }>`
	min-width: 30rem;
	display: flex;
	flex-direction: column;

	input,
	textarea {
		background: ${({ isEn }) =>
			isEn
				? "repeating-linear-gradient(45deg, white, white 0.5rem, #f2f2f2 0.5rem, #f2f2f2 1rem, white 1rem)"
				: colors.background};
	}

	> * {
		flex: 1;
	}

	@media (max-width: ${screenWidths.m}) {
		min-width: 10rem;
		width: 100%;
	}
`;

export const TextAreaContainer = styled(InputContainer)`
	display: block;

	textarea {
		height: 5rem;
		width: 100%;
		box-sizing: border-box;
	}
`;

export const InputLabel = styled.label`
	font-size: 0.75rem;
	color: #373737;
	margin-top: 0.25rem;
`;

export const CategoryOrder = styled.div`
	margin: 0 0.5rem;

	input {
		margin-left: 0.5rem;
		width: 3rem;
	}
`;

export const Buttons = styled.div`
	margin-top: 0.5rem;
	display: flex;
	justify-content: space-between;

	@media (max-width: ${screenWidths.s}) {
		flex-direction: column;

		${CategoryOrder} {
			margin: 0.5rem 0;
			display: flex;
			justify-content: center;
		}
	}
`;
