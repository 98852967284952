import { useAuth0 } from "@auth0/auth0-react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React, { ReactNode } from "react";
import { isBrowser } from "../../../util/browser";
import * as S from "./PrivateRoute.styles";

type Props = {
	children: ReactNode;
};

const PrivateRoute = ({ children }: Props) => {
	const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

	return (
		<>
			{isLoading ? (
				<S.RouteLoading icon={faSpinner} />
			) : isAuthenticated ? (
				<>{children}</>
			) : (
				<>
					{(() => {
						loginWithRedirect({
							redirectUri: isBrowser() ? window.location.toString() : "",
						});
						return null;
					})()}
				</>
			)}
		</>
	);
};

export default PrivateRoute;
