import styled from "styled-components";
import { screenWidths } from "../../../util/theme";

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media (max-width: ${screenWidths.m}) {
		flex-direction: column;
	}
`;

export const Category = styled.div`
	h2 {
		all: unset;
		font-size: 1.25rem;
		font-weight: bold;
	}

	padding: 0.25rem;
	margin-bottom: 0.5rem;
	margin-right: 0.5rem;
`;
