import { faSearch } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useState } from "react";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import * as S from "./AdminCustomers.styles";
import {
	CustomerQueryType,
	deleteCustomer,
	getCustomersByQuery,
} from "../../../api/customer.api";
import { TokenContext } from "../AdminLayout";
import CustomerCard from "../CustomerCard/CustomerCard";

export type Customer = {
	id: number;
	name: string;
	email: string;
	tel: string;
	note: string;
	isEn: boolean;
};

const SearchMap = {
	0: "Név",
	1: "Email",
	2: "Telefonszám",
};

type Props = {
	default: boolean;
};

const AdminCustomers = ({}: Props) => {
	const token = useContext(TokenContext);
	const [results, setResults] = useState<Customer[]>([]);
	const [selectedSearchType, setSelectedSearchType] =
		useState<CustomerQueryType>(CustomerQueryType.name);
	const [searchQuery, setSearchQuery] = useState<string>("");

	const handleSearch = async () => {
		try {
			const response = await getCustomersByQuery(token, {
				query: searchQuery,
				type: selectedSearchType,
			});

			if (!response.ok) {
				throw new Error("Error!");
			}

			const customers: Customer[] = await response.json();
			setResults(customers.sort((a, b) => a.name.localeCompare(b.name)));
		} catch (error) {
			console.log(error);
		}
	};

	const handleDelete = async (id: number) => {
		try {
			const response = await deleteCustomer(token, id);

			if (!response.ok) {
				throw new Error("Couldn't delete customer!");
			}

			handleSearch();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<S.Container>
			<S.SearchContainer>
				<Input
					placeholder={`${SearchMap[selectedSearchType]}`}
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
					type="search"
				/>
				<div>
					<input
						type="radio"
						id="name"
						name="name"
						checked={selectedSearchType === CustomerQueryType.name}
						onChange={() => setSelectedSearchType(CustomerQueryType.name)}
					/>
					<label htmlFor="name">Név</label>
					<input
						type="radio"
						id="email"
						name="email"
						checked={selectedSearchType === CustomerQueryType.email}
						onChange={() => setSelectedSearchType(CustomerQueryType.email)}
					/>
					<label htmlFor="email">E-mail</label>
					<input
						type="radio"
						id="tel"
						name="tel"
						checked={selectedSearchType === CustomerQueryType.phone}
						onChange={() => setSelectedSearchType(CustomerQueryType.phone)}
					/>
					<label htmlFor="tel">Telefon</label>
				</div>
				<Button appearance="primary" icon={faSearch} onClick={handleSearch}>
					Keresés
				</Button>
			</S.SearchContainer>
			<S.Results>
				<S.ResultsTitle>Találatok ({results.length} db)</S.ResultsTitle>
				{results.map((result) => (
					<CustomerCard
						customer={result}
						key={result.id}
						enabledActions={["delete"]}
						onActionClick={(action) => {
							if (action === "delete") {
								handleDelete(result.id);
							} else {
								console.error("Unknown action!");
							}
						}}
					/>
				))}
			</S.Results>
		</S.Container>
	);
};

export default AdminCustomers;
